import {mapMutations} from "vuex";

export default {
  name: "global-loader",
  props: {
    end: {
      type: Boolean,
      default: () => false,
    }
  },
  watch: {
    end: {
      immediate: true,
      handler(newVal){
        if(newVal === true){
          this.endTimer()
        }
      }
    },
    timer: {
      immediate: true,
      handler(newVal){
        if(newVal === this.endPoint){
          this.animate = true
          setTimeout(()=>{
            this.$emit('change-show')
          },1000)
        }
      }
    }
  },
  data() {
    return {
      timer: 0,
      endPoint: 100,
      maxPoint: 95,
      interval: 100,
      animate: false,
    }
  },
  computed: {},
  mounted() {
    this.loaderPercentage()
  },
  methods: {
    ...mapMutations({
      // changeLoginPopup:'popups/CHANGE_LOGIN_POPUP'
    }),
    loaderPercentage() {
      setInterval(() => {
        if (this.timer < this.maxPoint && !this.end) {
          this.timer++
        }
      }, this.interval)
    },
    endTimer() {
      setInterval(() => {
        if (this.timer < this.endPoint) {
          this.timer++
        }
      }, 10)
    },
  }
}