export default {
  name: "main-footer",
  components: {
  },
  data() {
    return {
    };
  },
  created() {},
  mounted() {
  },
  computed: {},
  methods: {
  },
};
