import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store'

Vue.use(VueRouter);

// const isAuthenticated = (to, from, next) => {
//   if (store.state.auth.isAuthenticated) {
//     next();
//     return
//   }
//   next('/sing-in')
// };

const routes = [
  {
    path: '/:lang?',
    name: 'main',
    component: () => import('../modules/home/index.vue')
  },
  {
    path: '/:lang?/poem',
    name: 'poem',
    component: () => import('../modules/poem/index.vue'),
    redirect: '/:lang?/poem/all',
    children: [
      {
        path: '/:lang?/poem/all',
        name: 'list',
        component: () => import('../modules/poem/section/list/index.vue'),
      },
      {
        path: '/:lang?/poem/:id',
        name: 'book',
        component: () => import('../modules/poem/section/book/index.vue'),
      },
    ]
  },
  {
    path: '/:lang?/story/:id',
    name: 'story',
    component: () => import('../modules/story/index.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let el = document.querySelector('.app');
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition
      } else {
        el.scrollTop = 0;

        return {x: 0, y: 0}
      }
    }
  }
});

export default router
