import {
  FIX_BODY,
  SHOW_GLOBAL_LOADER,
  CHANGE_SHOW_HINT_POPUP,
  CHANGE_SHOW_HINT,
} from '../mutation-types';
import {globalLoader, fixBody} from '@/helpers/variables/index'


const state = {
  globalLoader: false,
  fixBody: false,
  showHint: false,
  showHintPopup: false,
};

const getters = {
  fixBody: state => state.fixBody,
  globalLoader: state => state.globalLoader,
  showHint: state => state.showHint,
  showHintPopup: state => state.showHintPopup,
};

const mutations = {
  [CHANGE_SHOW_HINT](state, status) {
    state.showHint = status
  },
  [CHANGE_SHOW_HINT_POPUP](state, status) {
    state.showHintPopup = status
  },
  [SHOW_GLOBAL_LOADER](state, status) {
    state.globalLoader = status
  },
  [FIX_BODY](state, param) {
    const body = document.querySelector('body');
    if (param) {
      body.classList.add('fix');
    } else {
      body.classList.remove('fix');
    }
    state.fixBody = param
  },
};

export default {
  state,
  getters,
  mutations,
};
