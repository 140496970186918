<template>
  <div id="app" class="app">
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <main-header v-if="$route.name !== 'story'"/>
          <transition name="component-fade" mode="out-in">
            <router-view/>
          </transition>
        </div>
        <div class="wrapper-bottom">
          <main-footer/>
        </div>
      </div>
    </div>
    <hidden/>
    <transition name="component-fade">
      <global-loader v-if="showLoader" @change-show="showLoader = !showLoader" :end="!globalLoader"/>
    </transition>
  </div>
</template>

<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Hidden from '@/components/hidden/index.vue';
import MainHeader from '@/components/header/index.vue';
import MainFooter from '@/components/footer/index.vue';
import GlobalLoader from "./components/global-loader/index.vue";
import {isMobile, isIPad} from '@/helpers/variables/index'

export default {
  name: 'app',
  components: {
    GlobalLoader,
    Hidden,
    MainHeader,
    MainFooter
  },
  data() {
    return {
      showLoader: true,
    }
  },
  computed: {
    ...mapGetters({
      globalLoader: 'system/globalLoader'
    })
  },
  created() {
    this.fetchPoemList()
  },
  mounted() {
    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body').classList.add('iPad');
    }
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      fetchPoemList: 'poem/GET_POEM_LIST'
    }),
  }
}
</script>
