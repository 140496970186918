import {mapGetters, mapMutations} from "vuex";

export default {
  name: "main-header",
  data() {
    return {
      burger: false,
    };
  },
  watch: {
    '$route': {
      handler(newVal){
        if(newVal){
          this.burger = false
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      poemList: 'poem/poemList',
    }),
    poemId(){
      if(!this.poemList){
        return null
      } else if(!this.poemList.length){
          return null
        } else {
        return this.poemList[0].id
      }
    }
  },
  created() {
    if(this.$route.hash){
      setTimeout(()=>{
        this.scrollTo(this.$route.hash.slice(1))
      },300)
    }
  },
  methods: {
    ...mapMutations({
      // changeLoginPopup:'popups/CHANGE_LOGIN_POPUP'
    }),
    scrollTo(id) {
      this.burger = false
      if(this.$route.name !== 'main'){
        let url = this.$router.resolve({name: 'main', hash: `#${id}`})
        window.location.href = url.href
      } else {
        this.$nextTick(() => {
          const yOffset = -100
          const el = document.querySelector(`#${id}`)
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
          this.$router.push({
                hash: `#${id}`
              }
          ).catch(() => {
            console.log()
          });
          setTimeout(()=>{
            window.scrollTo({
              top: y,
              behavior: "smooth",
            })
          },20)
        })
      }
    }

  }
}