import {$http} from '@/utils/https'
import {translation} from '@/helpers/translation'
import {globalLoader} from '@/helpers/variables/index'
import {
  SET_TRANSLATIONS,
  SET_VARIABLES,
} from '../mutation-types';

import {
  GET_TRANSLATIONS,
  GET_VARIABLES
} from '../action-types';

const state = {
  translations: null,
  variables: null,
};

const getters = {
  variables: state => state.variables,
  translations: state => state.translations,
};

const actions = {
  [GET_VARIABLES]: async ({commit}) => {
    try {
      const response = await $http.get('v1/variables');
      console.log(response.data.data);
      commit(SET_VARIABLES, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_TRANSLATIONS]: async ({commit}) => {
    globalLoader(true);
    try {
      const response = await $http.get('v1/translations');
      let result = Object.assign(response.data, translation);
      commit(SET_TRANSLATIONS, result);
      return result;
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
};

const mutations = {
  [SET_VARIABLES](state, status) {
    state.variables = status;
  },
  [SET_TRANSLATIONS](state, status) {
    state.translations = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
