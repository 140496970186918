import {$http} from '@/utils/https'
import {translation} from '@/helpers/translation'
import {globalLoader} from '@/helpers/variables/index'
import {
  SET_BANNER,
} from '../mutation-types';

import {
  GET_BANNER,
} from '../action-types';

const state = {
  banner: null,
};

const getters = {
  banner: state => state.banner,
};

const actions = {
  [GET_BANNER]: async ({commit}) => {
    try {
      const response = await $http.get('v1/banner-images');
      commit(SET_BANNER, response.data.data);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [SET_BANNER](state, data) {
    state.banner = data;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
