//setting
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_VARIABLES = 'GET_VARIABLES';

//promo
export const GET_BANNER = 'GET_BANNER';

//poem
export const GET_POEM = 'GET_POEM';
export const GET_POEM_LIST = 'GET_POEM_LIST';
export const GET_POEM_POPUP = 'GET_POEM_POPUP';
export const GET_STORY = 'GET_STORY';