//setting
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_VARIABLES = 'SET_VARIABLES';

//system
export const FIX_BODY = 'FIX_BODY';
export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const CHANGE_SHOW_HINT = 'CHANGE_SHOW_HINT';
export const CHANGE_SHOW_HINT_POPUP = 'CHANGE_SHOW_HINT_POPUP';

//promo
export const SET_BANNER = 'SET_BANNER';

//poem
export const SET_POEM = 'SET_POEM';
export const CHANGE_POEM_LOADING = 'CHANGE_POEM_LOADING';
export const SET_POEM_LIST = 'SET_POEM_LIST';
export const CHANGE_POEM_LIST_LOADING = 'CHANGE_POEM_LIST_LOADING';
export const CHANGE_POEM_POPUP_LOADING = 'CHANGE_POEM_POPUP_LOADING';
export const SET_POEM_POPUP = 'SET_POEM_POPUP';
export const RESET_POEM_POPUP = 'RESET_POEM_POPUP';
export const SET_STORY = 'SET_STORY';
export const CHANGE_STORY_LOADING = 'CHANGE_STORY_LOADING';