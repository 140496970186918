import {$http} from '@/utils/https'
import {translation} from '@/helpers/translation'
import {globalLoader} from '@/helpers/variables/index'
import {
  SET_POEM, CHANGE_POEM_LOADING,
  CHANGE_POEM_LIST_LOADING, SET_POEM_LIST,
  SET_POEM_POPUP, CHANGE_POEM_POPUP_LOADING,
  RESET_POEM_POPUP, CHANGE_STORY_LOADING,
  SET_STORY,
} from '../mutation-types';

import {
  GET_POEM, GET_POEM_LIST, GET_POEM_POPUP, GET_STORY,
} from '../action-types';

const state = {
  poem: null,
  poemLoading: false,
  poemList: null,
  poemListLoading: false,
  poemPopup: null,
  poemPopupLoading: false,
  story: null,
  storyLoading: false,
};

const getters = {
  poem: state => state.poem,
  poemLoading: state => state.poemLoading,
  poemList: state => state.poemList,
  poemListLoading: state => state.poemListLoading,
  poemPopup: state => state.poemPopup,
  poemPopupLoading: state => state.poemPopupLoading,
  story: state => state.story,
  storyLoading: state => state.storyLoading,
};

const actions = {
  [GET_POEM]: async ({commit},id) => {
    commit(CHANGE_POEM_LOADING, true);
    try {
      const response = await $http.get(`v1/poems/${id}?include=pages`);
      commit(SET_POEM, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_POEM_LOADING, false);
    }
  },
  [GET_POEM_LIST]: async ({commit}) => {
    commit(CHANGE_POEM_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/poems?include=pages`);
      commit(SET_POEM_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_POEM_LIST_LOADING, false);
    }
  },
  [GET_POEM_POPUP]: async ({commit},id) => {
    commit(CHANGE_POEM_POPUP_LOADING, true);
    try {
      const response = await $http.get(`v1/pages/${id}`);
      commit(SET_POEM_POPUP, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_POEM_POPUP_LOADING, false);
    }
  },
  [GET_STORY]: async ({commit},id) => {
    commit(CHANGE_STORY_LOADING, true);
    try {
      const response = await $http.get(`v1/pages/${id}?include=content,related,poems`);
      commit(SET_STORY, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_STORY_LOADING, false);
    }
  },
};

const mutations = {
  [SET_POEM](state, data) {
    state.poem = data;
  },
  [CHANGE_POEM_LOADING](state, data) {
    state.poemLoading = data;
  },
  [SET_STORY](state, data) {
    state.story = data;
  },
  [CHANGE_STORY_LOADING](state, data) {
    state.storyLoading = data;
  },
  [SET_POEM_LIST](state, data) {
    state.poemList = data;
  },
  [CHANGE_POEM_LIST_LOADING](state, data) {
    state.poemListLoading = data;
  },
  [SET_POEM_POPUP](state, data) {
    state.poemPopup = data;
  },
  [RESET_POEM_POPUP](state) {
    state.poemPopup = null;
  },
  [CHANGE_POEM_POPUP_LOADING](state, data) {
    state.poemPopupLoading = data;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
